import React, { useState } from "react";
import SignatureFormatter from "./components/SignatureFormatter";
import styled from "@emotion/styled";
import "./styles.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @media (min-width: 860px) {
    flex-direction: row;
  }
`;

const FormContainer = styled.div`
  flex: 1 1 calc(50% - 50px);
  background-color: #FA5000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
`;

const Divider = styled.div`
  height: 20px;
  flex: 1 1 20px;
  align-self: stretch;
  background-color: #FA5000;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    fill: #fff;
  }
  @media (min-width: 860px) {
    height: auto;
    flex: 1 1 100px;
    background-color: transparent;
    svg {
      fill: #FA5000;
    }
  }
`;

const ResultContainer = styled.div`
  flex: 1 1 calc(50% - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
`;

const CornerIcon = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
  &:before {
    content: "";
    position:absolute;
    width: 250px;
    height: 150px;
    bottom: 0;
    right: 0;
    background-color: #FA5000;
    transform: rotate(-45deg);
    transform-origin: right top;
    z-index: -1;
    transition: all 0.3s ease-out;
  }

  &:hover {
    &:before {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
    
`;

const Logo = styled.a`
  color: #fff;
  svg {
    height: 100%;
    fill: #fff;
    transition: all 0.2s ease-out;
  }
  height: 50px;
  display: block;
  &:hover {
    svg {
      fill: #3768EE;
    }
  }
`;

const UserEditor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;

  font-family: "Calibre";
  font-weight: 400;
  font-size: 1rem;
  color: #32323c;
  line-height: 1.5625rem;

  margin: 2rem;
`;

const Header = styled.h1`
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 2rem;
  padding: 0;
  font-family: "National 2 Narrow";
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  line-height: 2.25rem;
  font-size: 2.5rem;
`;

const InputWrapper = styled.div`
  margin: 1rem 0;
`;

const InputLabel = styled.label`
  color: #ffffff;
  display: inline-block;
  margin-top: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  width: 100%;
  font-size: 1.125rem;
`;

const InputElement = styled.input`
  border: 1px solid #fff;
  font-size: 1.125rem;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  padding: 12px 14px 12px;
  &:focus {
    outline: none;
  }
`;

const Subtitle = styled.div`
  max-width: 450px;
  color: #fff;
`;

const Description = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  ol {
    padding-left: 1rem;
  }
  h3 {
    color:  #FA5000;
  }
  code {
    background-color: #FA5000;
    border-radius: 3px;
    padding: 2px 8px 3px;
    color: #fff;
    font-size: 1rem;
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  }
`;

const userData = {
  name: "Mohammed Fahsi",
  job: "Front-end Developer",
  availability: "Every weekday apart from wednesday",
  phone: "+31612345678"
};

export default function App() {
  const [user, setUser] = useState(userData);

  const userInfoChange = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  return (
    <Wrapper>
      <FormContainer>
        <Header>
          signature generator
        </Header>
        <Subtitle>
          Vul hieronder je gegevens in om je eigen Forty email-signature snel en eenvoudig to genereren.<br/>
          <br/>
          <strong>Alle velden met een * zijn verplicht!</strong> Als een veld niet van toepassing is kun je deze verwijderen door het veld leeg te maken.
        </Subtitle>
        <UserEditor>
          <InputWrapper>
            <InputLabel>Voornaam & Achternaam *</InputLabel>
            <InputElement
              name="name"
              onChange={userInfoChange}
              value={user.name}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Functie *</InputLabel>
            <InputElement
              name="job"
              onChange={userInfoChange}
              value={user.job}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Beschikbaarheid</InputLabel>
            <InputElement
              name="availability"
              onChange={userInfoChange}
              value={user.availability}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Prive telefoon</InputLabel>
            <InputElement
              name="phone"
              onChange={userInfoChange}
              value={user.phone}
            />
          </InputWrapper>
        </UserEditor>
      </FormContainer>
      <Divider>
        <svg viewBox="0 0 10 10" preserveAspectRatio="none">
          <polygon points="0,0 0,10 10,10" />
        </svg>
      </Divider>
      <ResultContainer>
        <Description>
          <h3>Gebruiksaanwijzing</h3>
          <ol>
            <li>Selecteerd de onderstaande signature in zijn volledigheid.</li>
            <li>Kopieer deze dmv de toetsencombinatie <code>CTRL + C</code> op Windows, of <code>Command + C</code> op OSX.<br/> <strong>of</strong> gebruik je rechtermuisknop en klik op copy/kopiëren. </li>
            <li>Ga via deze <a href="https://mail.google.com/mail/u/0/#settings/general" target="_blank" rel="noopener noreferrer">link</a> naar je gmail settings en plak je signature in het daarvoor bedoelde signature textveld.</li>
            <li>Geniet van je forty signature <span role="img" aria-label="success">🥳</span></li>
          </ol>
          <small>Gebruik je een andere email-client? Volg dan de instructies voor de desbetreffende email-clients hier <a href="https://support.office.com/en-us/article/create-and-add-a-signature-to-messages-8ee5d4f4-68fd-464a-a1c1-0e1c80bb27f2" target="_blank" rel="noopener noreferrer">Outlook</a>, <a href="https://support.apple.com/guide/mail/create-and-use-email-signatures-mail11943/mac" target="_blank" rel="noopener noreferrer">Apple Mail</a></small>
        </Description>
        <SignatureFormatter user={user} />
      </ResultContainer>
      <CornerIcon>
        <Logo href="https://forty.nl">
          <svg viewBox="0 0 250 395.52" xmlns="http://www.w3.org/2000/svg" >
            <polygon points="172.47 265.78 10.13 265.78 1 251.7 109.67 8.53 127.92 16.69 25.55 245.78 172.47 245.78 172.47 265.78"></polygon>
            <rect x="99.58" y="255.78" width="20" height="124.84"></rect>
            <path d="M239.63,217.68A100,100,0,0,1,197.2,29l11.22,1.36A122.26,122.26,0,0,1,250,122.24v85.45ZM200.38,50.28A80,80,0,0,0,230,196.65V122.24A102.26,102.26,0,0,0,200.38,50.28Z"></path>
            <rect x="230" y="207.69" width="20" height="38.04"></rect>
            <path d="M122.25,395.52A122.25,122.25,0,0,1,0,273.28v-151A122.38,122.38,0,0,1,122.25,0h5.51a122.11,122.11,0,0,1,80.66,30.4l-13.2,15A102.14,102.14,0,0,0,127.76,20h-5.51A102.36,102.36,0,0,0,20,122.24v151A102.24,102.24,0,0,0,122.25,375.52a107.92,107.92,0,0,0,24.45-2.79l4.54,19.48A127.74,127.74,0,0,1,122.25,395.52Z"></path>
            <path d="M189.23,376.57l-10.5-17a107,107,0,0,0,50.62-79.88l19.88,2.19A126.92,126.92,0,0,1,189.23,376.57Z"></path>
          </svg>
        </Logo>
      </CornerIcon>
    </Wrapper>
  );
}
