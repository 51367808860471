import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { shape, string } from "prop-types";

const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0rem;
`;

const EmailResult = styled.textarea`
  display: block;
  width: 100%;
  padding: 2rem;
  margin-bottom: 3rem;
  box-sizing: border-box;
`;

const EmailExample = styled.div`
  border: 1px solid #FA5000;
  padding: 2rem;
  background-color: #fff;
`;

const HtmlContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: ${({ showHtml }) => (showHtml ? '600px' : '0px' )};
  overflow: hidden;
  opacity: ${({ showHtml }) => (showHtml ? '1' : '0' )};
  transition: all 1s ease-out; 
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Calibre';
  font-weight: 500;
  line-height: 1;
  background-color: #FA5000;
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  border: none;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  margin-top: 3rem;
  &:hover {
    background-color: #d3c3f5;
    color: #FA5000;
  }
`;

const Available = e =>
  `<br/><span style="color:#444444;font-family:'Source Sans','Open Sans',sans-serif;"><i>${e}</i></span>`;
const Phone = e =>
  `<br/><span style="font-family:'Source Sans','Open Sans',sans-serif;"><a href="tel:${e}" style="text-decoration:none;color:#444444;">${e}</a></span>`;

const Template = ({
  name,
  job,
  availability,
  phone
}) => `<table width="320" cellspacing="0" cellpadding="0" border="0">
<tr>
  <td style="vertical-align: top; text-align:left;color:#444444;font-size:0.725em;line-height: 1.5;font-family:'Frank Ruhl Libre', Georgia; text-align:left">
      <span style="color:#FA5000;font-weight:bold;font-family:'Frank Ruhl Libre', Georgia;font-size:1.2em;">
        ${name}
      </span>
      <br/>
      <span style="color:#444444;font-family:'Source Sans','Open Sans',sans-serif;">
        ${job}
      </span>
      ${typeof availability !== "undefined" && availability !== '' ? Available(availability) : ""}
  </td>
</tr>
<tr>
    <td style="height: 1em;"></td>
</tr>
<tr>
  <td style="vertical-align: top; text-align:left;color:#444444;font-size:0.725em;line-height: 1.5;font-family:Tahoma,Geneva,sans-serif; text-align:left">
    <img width="100" height="100" src="https://www.forty.nl/email-animation.gif" alt="Forty" style="border:none; width: 100px; height: 100px;" />
  </td>
</tr>
<tr>
    <td style="height: 0.5em;"></td>
</tr>
<tr>
  <td style="vertical-align: top; text-align:left;color:#444444;font-size:0.725em;line-height: 1.5;font-family:'Source Sans','Open Sans',sans-serif; text-align:left">
    <span style="color:#444444;">Maliebaan 50</span>
    <br/>
    <span style="color:#444444;">3581 CS Utrecht</span>
    <br/>
    <span><a href="tel:+31 30 2737 422" style="text-decoration:none;color:#444444;">+31 (0)30 2737 422</a></span>
    ${typeof phone !== "undefined" && phone !== '' ? Phone(phone) : ""}
    <br/>
    <a href="https://www.forty.nl" style="text-decoration:underline;color:#444444;">www.forty.nl</a>
  </td>
</tr>
</table>
<table cellspacing="0" cellpadding="0" border="0" style="color: #444444; font-family: Tahoma,Geneva,sans-serif; line-height: 1.5; font-size: 0.725em;">
  <tr>
    <td style="height: 2em;"></td>
  </tr>
  <tr>
    <td style="font-size: 75%; max-width: 650px;">
      De informatie in dit bericht is vertrouwelijk. Het is daarom niet toegestaan dat u deze informatie openbaar maakt, vermenigvuldigt of verspreidt, tenzij de verzender aangeeft dat dit wel is toegestaan. Als dit e-mailbericht niet voor u bestemd is, vragen wij u vriendelijk maar dringend om het bericht en kopieën daarvan te vernietigen. Dit bericht is gecontroleerd op bekende virussen. Helaas kunnen wij niet garanderen dat het bericht dat u ontvangt volledig en tijdig verzonden is, of tijdig ontvangen wordt en vrij is van virussen of aantasting door derden.
    </td>
  </tr>
</table>
`;

const SignatureFormatter = ({ user }) => {
  const [userInfo, setUserInfo] = useState(user);
  const [htmlShown, setHtmlShown] = useState(false);
  const textAreaRef = useRef(null);
  const signatureElementRef = useRef(null);

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  const Result = Template(userInfo);

  return (
    <Wrapper>
      <EmailExample ref={signatureElementRef} dangerouslySetInnerHTML={{ __html: Result }} />
      <Button onClick={() => (setHtmlShown(!htmlShown))}>Toon html</Button>
      <HtmlContainer showHtml={htmlShown}>
        <EmailResult readOnly rows="30" ref={textAreaRef} value={Result} />
      </HtmlContainer>
    </Wrapper>
  );
};

SignatureFormatter.propTypes = {
  user: shape({
    name: string.isRequired,
    job: string.isRequired,
    availability: string,
    phone: string
  }).isRequired
};

export default SignatureFormatter;
